import Api from "@/services/Api";

export default {
  createIncomingCall(payload) {
    return Api().post("/support/incoming/calls", payload);
  },
  retrieveCustomer(payload) {
    return Api().get(`/admin/customers/filter?serial_number=${payload}`);
  },
  retrieveIncomingCalls() {
    return Api().get("/support/incoming/calls");
  },
  retrieveIncomingCall(callId) {
    return Api().get(`/support/incoming/calls/${callId}`);
  },
  deleteCallsById(callId) {
    return Api().delete(`/support/issues/${callId}`);
  },
  resolveCallsById(callId) {
    return Api().put(`/support/incoming/calls/${callId}/status`, { status: 'resolved' });
  },
};