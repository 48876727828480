import store from "../../store";
import router from "../../router";
import CallService from "../../services/CallService";

export default {
  getIssues() {
    store.dispatch("setLoading", true);
    const response = CallService.getIssues()
      .then((result) => {
        const { status, data: { issues } } = result.data
        if (status) {
          return { status: true, issues };
        } else {
          return { status: false }
        }
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Login",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  retrieveCustomer(customerSerial) {
    store.dispatch("setLoading", true);
    const response = CallService.retrieveCustomer(customerSerial)
      .then((result) => {
        const { status, data: { customer } } = result.data
        if (status) {
          return { status: true, customer };
        } else {
          return { status: false }
        }
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Login",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  createIncomingCall(payload) {
    store.dispatch("setLoading", true);
    const response = CallService.createIncomingCall(payload)
      .then((result) => {
        const { status, message } = result.data
        if (status) {
          return { status: true };
        } else {
          return { status: false, message }
        }
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Login",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  retrieveIncomingCalls() {
    store.dispatch("setLoading", true);
    const response = CallService.retrieveIncomingCalls()
      .then((result) => {
        const { status, data: { incomingcalls } } = result.data
        if (status) {
          return { status: true, incomingcalls };
        } else {
          return { status: false }
        }
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Login",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  retrieveIncomingCall(callId) {
    store.dispatch("setLoading", true);
    const response = CallService.retrieveIncomingCall(callId)
      .then((result) => {
        const { status, data: { incomingcall } } = result.data
        if (status) {
          return { status: true, incomingcall };
        } else {
          return { status: false }
        }
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Login",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  resolveCall(callId) {
    store.dispatch("setLoading", true);
    const response = CallService.resolveCallsById(callId)
      .then((result) => {
        const { status } = result.data
        if (status) {
          return { status: true };
        } else {
          return { status: false }
        }
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Login",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  deleteCall(callId) {
    store.dispatch("setLoading", true);
    const response = CallService.deleteCallsById(callId)
      .then((result) => {
        const { status } = result.data
        if (status) {
          return { status: true };
        } else {
          return { status: false }
        }
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Login",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
};
