<template>
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card">
        <form @submit.prevent="addIncomingCall()">
          <div class="card-header">
            <h4>Add Incoming Call</h4>
          </div>
          <div class="card-body pb-0">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Caller Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="fields.caller_name"
                    placeholder="Thomas Usman"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Caller Phone number</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="fields.caller_phone"
                    placeholder="0700 000 0000"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Serial Number</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      v-model="fields.serial_number"
                      placeholder="48483422"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-info"
                        type="button"
                        :class="{ 'btn-progress': loading }"
                        @click.prevent="retrieveCustomer()"
                      >
                        {{ loading ? "Searching..." : "Search" }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <fieldset v-if="typeof customerExist == 'boolean'">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Customer Name</label>
                    <input
                      type="text"
                      class="form-control"
                      :disabled="customerExist"
                      v-model="fields.customer_name"
                      placeholder="Christopher Okokon"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Customer type</label>
                    <div class="form-group">
                      <select
                        class="form-control"
                        :disabled="customerExist"
                        v-model="fields.customer_type"
                      >
                        <option value="" disabled>Select an option</option>
                        <option value="existing">Existing Customer</option>
                        <option value="prospect">Prospective Customer</option>
                        <option value="agent">Agent</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Phone</label>
                    <input
                      type="text"
                      class="form-control"
                      :disabled="customerExist"
                      v-model="fields.phone"
                      maxlength="11"
                      placeholder="0700 000 0000"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group mb-0">
                    <label>State</label>
                    <div class="form-group">
                      <select
                        class="form-control"
                        :disabled="customerExist"
                        v-model="fields.state"
                      >
                        <option value="" disabled>Select an option</option>
                        <option
                          v-for="state in states"
                          :value="state.alias"
                          :key="state.alias"
                        >
                          {{ state.state }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group mb-0">
                    <label>Local Government Area</label>
                    <div class="form-group">
                      <select
                        class="form-control"
                        :disabled="customerExist"
                        v-model="fields.lga"
                      >
                        <option value="" disabled>Select an option</option>
                        <option
                          v-for="lga in selectedLGA"
                          :value="lga"
                          :key="lga"
                        >
                          {{ lga }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Action</label>
                  <div class="form-group">
                    <select class="form-control" v-model="fields.action">
                      <option value="" disabled>Select an option</option>
                      <option value="Resolved">Resolved</option>
                      <option value="Create Ticket">Create Ticket</option>
                      <option value="Create Task">Create Task</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Status</label>
                  <div class="form-group">
                    <select class="form-control" v-model="fields.status">
                      <option value="" disabled>Select an option</option>
                      <option value="resolved">Resolved</option>
                      <option value="unresolved">Unresolved</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <fieldset v-if="fields.action == 'Create Ticket'">
              <div class="row">
                <div class="col-12">
                  <h6>Create Ticket</h6>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Title</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="fields.ticket.title"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Description</label>
                    <textarea
                      class="form-control"
                      v-model="fields.ticket.description"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Category of Issue</label>
                    <div class="form-group">
                      <select
                        class="form-control"
                        v-model="fields.ticket.issue_category_id"
                      >
                        <option value="" disabled>Select an option</option>
                        <option
                          v-for="issue in issues"
                          :value="issue.id"
                          :key="issue.id"
                        >
                          {{ issue.title }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Assign to</label>
                    <div class="form-group">
                      <select
                        class="form-control"
                        v-model="fields.ticket.assign_to"
                      >
                        <option value="" disabled>Select an option</option>
                        <option
                          v-for="employee in employees"
                          :value="employee.id"
                          :key="employee.id"
                        >
                          {{ employee.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Priority</label>
                    <div class="form-group">
                      <select
                        class="form-control"
                        v-model="fields.ticket.priority"
                      >
                        <option value="" disabled>Select an option</option>
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Community name</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="fields.ticket.community"
                    />
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset v-if="fields.action == 'Create Task'">
              <div class="row">
                <div class="col-12">
                  <h6>Create Task</h6>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Title</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="fields.task.title"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Description</label>
                    <textarea
                      class="form-control"
                      v-model="fields.task.description"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Assign to</label>
                    <div class="form-group">
                      <select
                        class="form-control"
                        v-model="fields.task.assign_to"
                      >
                        <option value="" disabled>Select an option</option>
                        <option
                          v-for="employee in employees"
                          :value="employee.id"
                          :key="employee.id"
                        >
                          {{ employee.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Priority</label>
                    <div class="form-group">
                      <select
                        class="form-control"
                        v-model="fields.task.priority"
                      >
                        <option value="" disabled>Select an option</option>
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Start date</label>
                    <input
                      type="datetime-local"
                      class="form-control"
                      v-model="fields.task.start_date"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Due date</label>
                    <input
                      type="datetime-local"
                      class="form-control"
                      v-model="fields.task.due_date"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Add sub task</label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Go to the store"
                        v-model="singleTask"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-default"
                          type="button"
                          @click.prevent="addChecklist()"
                        >
                          + Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="this.fields.task.checklist.length > 0">
                <div class="col">
                  <div class="table-responsive">
                    <table class="table table-striped table-md">
                      <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                      <tr
                        v-for="(item, index) in fields.task &&
                        fields.task.checklist"
                        :key="index"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.title }}</td>
                        <td>
                          <div class="badge badge-danger">Not completed</div>
                        </td>
                        <td>
                          <a
                            href="#"
                            class="btn btn-icon btn-sm btn-danger"
                            @click.prevent="removeTask(index)"
                          >
                            <i class="fas fa-times"></i>
                          </a>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </fieldset>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Comment</label>
                  <textarea
                    class="form-control"
                    v-model="fields.comment"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-right pt-0">
            <button
              class="btn btn-primary"
              :class="{ 'btn-progress': loading }"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import CallHelpers from "@/functions/actions/CallRequest";
import GeneralHelpers from "@/functions/actions/GeneralRequest";
export default {
  name: "addIncomingCall",
  computed: {
    ...mapState(["loading"]),
    selectedLGA() {
      let results = this.states.find(
        (state) => state.alias === this.fields.state
      );
      return results ? this.$globalFunc.sortBy(results.lgas) : [];
    },
  },
  mounted() {
    this.retrieveIssues();
    this.retrieveEmployees();
  },
  data() {
    return {
      states: this.$globalFunc.locations(),
      issues: [],
      employees: [],
      customerExist: null,
      singleTask: "",
      fields: {
        customer_type: "",
        state: "",
        lga: "",
        phone: "",
        action: "",
        status: "",
        ticket: {
          issue_category_id: "",
          assign_to: "",
          priority: "",
          community: "",
        },
        task: {
          assign_to: "",
          priority: "",
          checklist: [],
        },
      },
    };
  },
  methods: {
    async retrieveIssues() {
      const response = await GeneralHelpers.getIssues();
      if (response.status) {
        this.issues = response.issues;
      } else {
        this.showAlert(
          "Error occured",
          `${response.message}. Kindly reload the page`,
          "error"
        );
      }
    },
    async retrieveEmployees() {
      const response = await GeneralHelpers.getUsersByType("all");
      if (response.status) {
        this.employees = response.users;
      } else {
        this.showAlert(
          "Error occured",
          `${response.message}. Kindly reload the page`,
          "error"
        );
      }
    },
    async retrieveCustomer() {
      if (this.fields.serial_number) {
        const response = await CallHelpers.retrieveCustomer(
          this.fields.serial_number
        );
        if (response.status) {
          const { customer_name, customer_type, phone, lga, state, id } =
            response.customer;
          this.fields = {
            ...this.fields,
            ...{ customer_name, customer_type, phone, lga },
          };
          this.fields.state = state.toLowerCase().replace(" ", "_");
          this.fields.customer_id = id;
          this.customerExist = true;
        } else {
          this.customerExist = false;
          this.fields = {
            ...this.fields,
            ...{ customer_name: "", customer_type: "", lga: "", state: "" },
          };
        }
      } else {
        this.showAlert("Validation Error", "Kindly provide a serial number");
      }
    },
    async addIncomingCall() {
      if (this.validateData()) {
        const response = await CallHelpers.createIncomingCall(this.fields);
        if (response.status) {
          this.showAlert(
            "Success",
            "Incoming call has been added successfully",
            "success"
          );
          this.$router.push({
            name: "All Incoming Calls",
          });
        } else {
          this.showAlert(
            "Error occured",
            `${response.message}. Kindly reload the page`,
            "error"
          );
        }
      }
    },
    addChecklist() {
      if (this.singleTask) {
        this.fields.task.checklist = [
          ...this.fields.task.checklist,
          { title: this.singleTask },
        ];
        this.singleTask = "";
      } else {
        this.showAlert("Validation Error", "Kindly enter a task", "error");
      }
    },
    removeTask(taskIndex) {
      this.fields.task.checklist.splice(taskIndex, 1);
    },
    validateData() {
      if (this.fields.caller_name) {
        if (this.fields.caller_phone) {
          if (this.fields.serial_number) {
            if (this.fields.customer_name) {
              if (this.fields.phone) {
                if (this.fields.state) {
                  if (this.fields.lga) {
                    if (this.fields.customer_type) {
                      if (this.fields.customer_type !== "existing") {
                        return true;
                      } else {
                        if (this.fields.serial_number) {
                          return true;
                        } else {
                          this.showAlert(
                            "Validation error",
                            "Kindly provide customer serial number",
                            "warning"
                          );
                        }
                      }
                    } else {
                      this.showAlert(
                        "Validation error",
                        "Select the customer type",
                        "warning"
                      );
                    }
                  } else {
                    this.showAlert(
                      "Validation error",
                      "Select a customer lga",
                      "warning"
                    );
                  }
                } else {
                  this.showAlert(
                    "Validation error",
                    "Select a customer state",
                    "warning"
                  );
                }
              } else {
                this.showAlert(
                  "Validation error",
                  "Provide a phone number",
                  "warning"
                );
              }
            } else {
              this.showAlert(
                "Validation error",
                "Provide the customer's name",
                "warning"
              );
            }
          } else {
            return true;
          }
        } else {
          this.showAlert(
            "Validation error",
            "Provide the caller's phone",
            "warning"
          );
        }
      } else {
        this.showAlert(
          "Validation error",
          "Provide the caller's name",
          "warning"
        );
      }
    },
    showAlert(title, text, type) {
      this.$fire({
        title,
        text,
        type: type ? type : "warning",
      });
    },
  },
};
</script>
<style lang="">
</style>